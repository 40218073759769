import React from 'react';
import styles from "./not-found.module.css";
import classNames from "classnames/bind";
import ShiiLoading from "assets/images/difficulty_alert_shii.webp";

const cx = classNames.bind(styles);

function NotFound() {
  const ShiiLoadingImage = React.memo(function ShiiLoadingImage() {
    return <img src={ShiiLoading} alt='shii-loading' />;
  });

  return (
    <div className={cx('not-found')}>
      <ShiiLoadingImage />
      <p className={cx('emphasis')}>
        잘못된 경로입니다.
      </p>
    </div>
  )
}

export default React.memo(NotFound);