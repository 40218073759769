import ReactDOM from 'react-dom/client';
import { RecoilRoot, RecoilEnv } from 'recoil';
import App from './App';
import 'styles/globals.css';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
