import StandbyPanel from "./quickmenu/standby/standby-panel";
import SettingPanel from "./quickmenu/setting/setting-panel";
import styles from "./sidebar.module.css";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface sidebarProps {
  position: 'left' | 'right',
  empty?: boolean,
};

export default function Sidebar({ position, empty = false }: sidebarProps) {
  return (
    <div className={cx('aside', `${position}-side`)}>
      {
        !empty &&
        <>
          <div className={cx('aside-empty-space')}></div>
          <div className={cx('quickmenu-panel')}>
            <StandbyPanel />
            <SettingPanel />
          </div>
        </>
      }
    </div>
  );
}