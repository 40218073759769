import React from 'react';
import styles from "./loading.module.css";
import classNames from "classnames/bind";
import ShiiLoading from "assets/images/difficulty_alert_shii.webp";

const cx = classNames.bind(styles);

function Loading() {
  const ShiiLoadingImage = React.memo(function ShiiLoadingImage() {
    return <img src={ShiiLoading} alt='shii-loading' />;
  });

  return (
    <div className={cx('loading')}>
      <ShiiLoadingImage />
      <p className={cx('emphasis')}>
        페이지를 로딩 중입니다.
      </p>
    </div>
  )
}

export default React.memo(Loading);