import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { difficultyNumberState, fetchingFromEmptyState } from "store";
import DifficultyNumber from "./difficulty-number";
import styles from "./difficulty.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Difficulty() {
  const [hoveredNumDif, setHoveredNumDif] = useState<number>(0);
  const [currentNumDif, setCurrentNumDif] = useRecoilState(difficultyNumberState);
  const setFetchingFromEmpty = useSetRecoilState(fetchingFromEmptyState);

  /**
   * Changes current difficulty number,
   * or changes difficulty range by [minimum number ~ maximum number].
   * 
   * @param event - A mouse event (mostly onClick event)
   * @returns 
   */
  const changeCurrentDifficultyNum = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const selected: number = +target.innerText;
    if (!Number.isInteger(selected)) return;

    switch (currentNumDif.selectedCount) {
      case 0:
        setCurrentNumDif({
          selectedCount: 1,
          firstNum: selected
        });
        break;
      case 1:
        if (currentNumDif.firstNum === selected) {
          setFetchingFromEmpty(true);
          setCurrentNumDif({
            selectedCount: 0
          });
        } else {
          if (selected < (currentNumDif.firstNum as number)) {
            setCurrentNumDif({
              selectedCount: 2,
              firstNum: selected,
              secondNum: currentNumDif.firstNum
            });
          } else {
            setCurrentNumDif({
              selectedCount: 2,
              firstNum: currentNumDif.firstNum,
              secondNum: selected
            });
          }
        }
        break;
      case 2:
        if (selected === currentNumDif.firstNum) {
          setCurrentNumDif({
            selectedCount: 1,
            firstNum: currentNumDif.secondNum
          });
        } else if (selected === currentNumDif.secondNum) {
          setCurrentNumDif({
            selectedCount: 1,
            firstNum: currentNumDif.firstNum
          });
        }
        break;
    }
  }

  /**
   * Sets hovered difficulty number to hovered number.
   * 
   * @param event - A mouse event (mostly mouseOver)
   * @returns 
   */
  const mouseOvered = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const hovered: number = +target.innerText;
    if (!Number.isInteger(hovered)) return;

    setHoveredNumDif(hovered);
  }

  /**
   * Sets hovered difficulty number to zero.
   * 
   * @param event - A mouse event (mostly mouseOut)
   * @returns 
   */
  const mouseOut = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const hovered: number = +target.innerText;
    if (!Number.isInteger(hovered)) return;

    setHoveredNumDif(0);
  }

  /**
   * Checks difficulty is in-ranged, between (selected ~ hovered).
   * Works only when one difficulty is selected, and another difficulty is hovered.
   * 
   * @param difficulty - Difficulty number
   * @returns Whether a difficulty is in between (selected number ~ hovered number).
   */
  const checkInRangedWhenHover = (difficulty: number): boolean => {
    if (currentNumDif.selectedCount !== 1 || hoveredNumDif === 0) return false;

    if (((currentNumDif.firstNum as number) < difficulty && difficulty < hoveredNumDif) ||
      (hoveredNumDif < difficulty && difficulty < (currentNumDif.firstNum as number))) {
      return true;
    }
    return false;
  }

  /**
   * Returns difficulty's state.
   * (e.g. difficulty is selected, 
   * or difficulty is in-ranged, between (minimum number ~ maximum number).)
   * 
   * @param difficulty - Difficulty number
   * @returns Current state of difficulty.
   */
  const getNumberRange = (difficulty: number): '' | 'selected' | 'in-range' | 'out-range' => {
    switch (currentNumDif.selectedCount) {
      case 0:
        return '';
      case 1:
        if (currentNumDif.firstNum === difficulty) {
          return 'selected';
        }
        return '';
      case 2:
        if (currentNumDif.firstNum === difficulty ||
          currentNumDif.secondNum === difficulty) {
          return 'selected';
        } else if ((currentNumDif.firstNum as number) < difficulty &&
          difficulty < (currentNumDif.secondNum as number)) {
          return 'in-range'
        } else {
          return 'out-range';
        }
      default:
        return '';
    }
  }

  /**
   * Sets numbers-list component, composited with [start ~ end] difficulty.
   * 
   * @param start - First element's difficulty
   * @param end - Last element's difficulty
   * @returns 
   */
  const setNumbersLists = (start: number, end: number) => {
    return (
      <ol className={cx('numbers-list')}>
        {
          Array.from({ length: end - start + 1 }).map((_, index) => {
            const difficulty: number = start + (index as number);
            return (
              <DifficultyNumber
                key={difficulty}
                difficulty={difficulty}
                range={
                  checkInRangedWhenHover(difficulty) ?
                  'in-range' :
                  getNumberRange(difficulty)
                } />
            )
          })
        }
      </ol>
    );
  }

  return (
    <div className={cx('difficulty-select')}>
      <p className="noselect">
        DIFFICULTY
      </p>
      <div
        className={cx('difficulty-numbers')}
        onClick={changeCurrentDifficultyNum}
        onMouseOver={mouseOvered}
        onMouseOut={mouseOut}
      >
        {
          setNumbersLists(10, 13)
        }
        {
          setNumbersLists(14, 17)
        }
      </div>
    </div>
  );
}