import { atom } from "recoil";

/* Difficulty (sidebar/quickmenu/standby) */
export interface modeType {
  modeType?: 'lunar' | 'solar'
};

export const modeState = atom<modeType>({
  key: "modeState",
  default: {modeType: 'lunar'}
});

export interface difficultyNumberType {
  selectedCount: number,
  firstNum?: number,
  secondNum?: number
}

export const difficultyNumberState = atom<difficultyNumberType>({
  key: "difficultyNumberState",
  default: {
    selectedCount: 0,
  }
})

/* Settings (sidebar/quickmenu/setting) */
export const musicTitleDisplayState = atom<boolean>({
  key: "musicTitleDisplayState",
  default: true,
});

export const dlcDisplayState = atom<boolean>({
  key: "dlcDisplayState",
  default: true,
});

export const difficultyDisplayState = atom<boolean>({
  key: "difficultyDisplayState",
  default: false,
});

export const bpmDisplayState = atom<boolean>({
  key: "bpmDisplayState",
  default: false,
});

export const ascendingOrderState = atom<boolean>({
  key: "ascendingOrderState",
  default: false,
});

/* Loading */
export const fetchingFromEmptyState = atom<boolean>({
  key: "fetchingFromEmptyState",
  default: true,
})