import DifficultyArrow from "./difficulty-arrow";
import Difficulty from "./difficulty";
import ModeChange from "./mode-change";
import styles from "./standby-panel.module.css";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function StandbyPanel() {
  return (
    <div className={cx('standby-panel')}>
      <ModeChange />
      <Difficulty />
      <DifficultyArrow />
    </div>
  );
}