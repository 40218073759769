import React, { Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Header from 'components/common/header';
import Footer from 'components/common/footer';
import Loading from 'components/common/loading';
import NotFound from 'components/common/not-found';
import Sidebar from 'components/sidebar/sidebar';
import styles from './App.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const Home = React.lazy(() => import('components/home/home'));

function App() {
  return (
    <BrowserRouter>
      <Sidebar position='left' empty={true} />
      <div className={cx('main')}>
        <Header />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/*' element={<NotFound />}></Route>
          </Routes>
        </Suspense>
        <Footer />
      </div>
      <Sidebar position='right' />
    </BrowserRouter>
  );
}

export default App;
