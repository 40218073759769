import React from 'react';
import { useSetRecoilState } from 'recoil';
import { difficultyNumberState, fetchingFromEmptyState } from 'store';
import { Link } from 'react-router-dom';
import styles from "./header.module.css";
import classNames from 'classnames/bind';
import MainLogo from "assets/images/logo/main_logo.webp"

const cx = classNames.bind(styles);

function Header() {
  const setNumDif = useSetRecoilState(difficultyNumberState);
  const setFetchingFromEmpty = useSetRecoilState(fetchingFromEmptyState);

  const difficultyReset = () => {
    setFetchingFromEmpty(true);
    setNumDif({
      selectedCount: 0
    });
  }

  return (
    <header>
      <nav className={cx('nav-wrapper')}>
        <Link to='/' onClick={difficultyReset}>
          <img src={MainLogo} alt='main-logo' className={cx('main-logo')} />
        </Link>
      </nav>
    </header>
  );
}

export default React.memo(Header);