import { useRecoilState } from "recoil";
import { modeState } from "store";
import styles from "./mode-change.module.css";
import classNames from "classnames/bind";
import LunarLogo from "assets/images/logo/lunar_logo.webp"
import SolarLogo from "assets/images/logo/solar_logo.webp"

const cx = classNames.bind(styles);

export default function ModeChange() {
  const [mode, setMode] = useRecoilState(modeState);

  const changeMode = (): void => {
    if (mode.modeType === 'lunar') {
      setMode({ modeType: 'solar' });
    } else {
      setMode({ modeType: 'lunar' });
    }
  }

  return (
    <div className={cx('mode-select')}>
      <p className="noselect">
        MODE
      </p>
      <button type="button" onClick={changeMode}>
        {
          mode.modeType === 'lunar' ?
            <img
              src={LunarLogo}
              alt="mode-change-logo"
              className={cx('standby-mode-logo', `${mode.modeType}-mode`)} /> :
            <img
              src={SolarLogo}
              alt="mode-change-logo"
              className={cx('standby-mode-logo', `${mode.modeType}-mode`)} />
        }
      </button>
    </div>
  );
}