import styles from "./setting-checkbox.module.css";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface checkboxProps {
  id: string,
  text: string,
  defaultChecked?: boolean,
  onClick: () => void;
};

function SettingCheckbox({ id, text, defaultChecked = true, onClick }: checkboxProps) {
  return (
    <li className={cx('user-setting')}>
      <label htmlFor={id} className={cx('setting-checkbox')}>
        <input
          id={id}
          type="checkbox"
          defaultChecked={defaultChecked}
          onClick={onClick} />
        <span className={cx('checkbox-text')}>{text}</span>
        <span className={cx('checkmark')}></span>
      </label>
    </li>
  )
}

export default SettingCheckbox;