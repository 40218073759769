import styles from "./difficulty-number.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

interface difficultyNumberProps {
  difficulty: number,
  range?: '' | 'selected' | 'in-range' | 'out-range'
};

export default function DifficultyNumber(
  { difficulty, range = ''}: difficultyNumberProps
) {
  return (
    <li
      className={cx('difficulty', range)}>
      {difficulty}
    </li>
  );
}