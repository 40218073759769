import LeftArrow from "assets/images/left_arrow.webp";
import styles from "./difficulty-arrow.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export default function Arrow() {
  return (
    <div className={`hide ${cx('difficulty-arrow')}`}>
      <button type="button" className={cx('difficulty-type-change')}>
        <img
          src={LeftArrow}
          alt='left-arrow'
          className={cx('left-arrow')} />
      </button>
      <button type="button" className={cx('difficulty-type-change')}>
        <img src={LeftArrow}
          alt='right-arrow'
          className={cx('right-arrow')} />
      </button>
    </div>
  );
}