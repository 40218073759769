import { useRecoilState } from "recoil";
import {
  musicTitleDisplayState,
  dlcDisplayState,
  difficultyDisplayState,
  bpmDisplayState,
  ascendingOrderState
} from "store";
import SettingCheckbox from "./setting-checkbox";
import styles from "./setting-panel.module.css";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default function SettingPanel() {
  const [musicTitleDisplay, setMusicTitleDisplay] = useRecoilState(musicTitleDisplayState);
  const [dlcDisplay, setDlcDisplay] = useRecoilState(dlcDisplayState);
  const [difficultyDisplay, setDifficultyDisplay] = useRecoilState(difficultyDisplayState);
  const [bpmDisplay, setBpmDisplay] = useRecoilState(bpmDisplayState);
  const [ascendingOrder, setAscendingOrder] = useRecoilState(ascendingOrderState);

  const changeMusicTitleDisplay = (): void => {
    setMusicTitleDisplay(current => !current);
  }
  const changeDlcDisplay = (): void => {
    setDlcDisplay(current => !current);
  }
  const changeDifficultyDisplay = (): void => {
    setDifficultyDisplay(current => !current);
  }
  const changeBpmDisplay = (): void => {
    setBpmDisplay(current => !current);
  }
  const changeSortOrder = (): void => {
    setAscendingOrder(current => !current);
  }

  return (
    <div className={cx('setting-panel')}>
      <ul className={cx('user-setting-list')}>
        <SettingCheckbox
          id="show-music-title"
          text="곡 제목"
          defaultChecked={musicTitleDisplay}
          onClick={changeMusicTitleDisplay} />
        <SettingCheckbox
          id="show-dlc"
          text="DLC"
          defaultChecked={dlcDisplay}
          onClick={changeDlcDisplay} />
        <SettingCheckbox
          id="show-difficulty"
          text="난이도"
          defaultChecked={difficultyDisplay}
          onClick={changeDifficultyDisplay} />
        <SettingCheckbox
          id="show-bpm"
          text="BPM"
          defaultChecked={bpmDisplay}
          onClick={changeBpmDisplay} />
        <SettingCheckbox
          id="ascending-order"
          text="오름차순 정렬"
          defaultChecked={ascendingOrder}
          onClick={changeSortOrder} />
      </ul>
    </div>
  );
}